/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

.bg-table-primary {
  background-color: rgba(255, 230, 0, 0.201) !important;
}

.bg-table-secondary {
  background-color: rgba(205, 248, 255, 1) !important;
}

.text-green {
  color: green !important;
}

.text-red {
  color: red !important;
}

.text-blue {
  color: blue !important;
}

.underline {
  border-bottom: 3px double black !important;
}

.animate-swipe-enter {
  transition: 'all 400ms';
  transform: translateY(0);
  /* top: 0; */
}

.anime-swipe-exit {
  transition: 'all 400ms';
  transform: translateY(-120);
  /* top: -40; */
}
